<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card v-loading.body="isLoading">
        <template #header>
          <h2 class="heading page-heading">
            {{ $t('page_unit_edit.title', {unitTitle: form.title}) }}
          </h2>
        </template>
        <el-form
            label-position="top"
            :rules="formRules"
            size="medium"
            status-icon
            :model="form"
            ref="form"
        >
          <el-row>
            <el-col>
              <el-form-item>
                <el-radio-group disabled v-model="form.unitType">
                  <el-radio :label="$options.unitTypes.UNIT_TYPE_INSTITUTION">
                    {{ $t('page_unit_edit.fields.unit_type_institution') }}
                  </el-radio>
                  <el-radio :label="$options.unitTypes.UNIT_TYPE_EMERGENCY">
                    {{ $t('page_unit_edit.fields.unit_type_emergency') }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                  prop="title"
                  :label="$t('page_unit_edit.fields.unit_name')"
              >
                <el-input v-model="form.title"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item
                  prop="banner"
                  :label="$t('page_unit_edit.fields.background_image')"
              >
                <v-file-input
                    v-model="form.banner"
                    :placeholder="
                        $t(
                            'page_unit_edit.fields.background_image_placeholder',
                             {size: $options.BANNER_SIZE}
                       )
                      "
                    :acceptable-formats="['image/png', 'image/jpeg']"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  prop="photo"
                  :label="$t('page_unit_edit.fields.image_thumbnail')"
              >
                <v-file-input
                    :placeholder="
                        $t(
                            'page_unit_edit.fields.background_image_placeholder',
                             {size: $options.PHOTO_SIZE}
                       )
                      "
                    v-model="form.photo"
                    :acceptable-formats="['image/png', 'image/jpeg']"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                  prop="description"
                  :label="$t('page_unit_edit.fields.unit_description')"
              >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    :maxlength="200"
                    :label="$t('page_unit_edit.fields.unit_description')"
                />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="unitChildren"
                            :label="$t('page_unit_edit.fields.unit_children_label')"
              >
                <el-select :placeholder="$t('page_unit_edit.fields.unit_children')"
                           :disabled="!isEmergency"
                           v-model="form.unitChildren"
                           multiple
                           :no-data-text="unitChildrenNoDataText"
                >
                  <el-option v-for="child in availableUnitChildren"
                             :key="child.unitId"
                             :value="child.unitId"
                             :label="child.unitTitle"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-button type="primary" @click="handleUpdateUnit">
                {{ $t('page_unit_edit.btn_update') }}
              </el-button>
              <el-button @click="goToUnitPage">
                {{ $t('page_unit_edit.btn_cancel') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import VFileInput from '../../../components/ui/v-file-input/VFileInput'
import {mapState} from 'vuex'
import {UNIT_TYPE_EMERGENCY, UNIT_TYPE_INSTITUTION} from '../shared/constants/units-constants'
import {
  fetchUnitEditData,
  updateUnit,
  fetchAvailableUnitChildrenByUnitId
} from '../../../client/unit-page/services/unit-sevice'
import {UNIT_ABOUT_ROUTE_NAME} from '../../../shared/constants/app-routes-names'

export default {
  name: 'UnitEdit',
  components: {
    VFileInput
  },
  unitTypes: {
    UNIT_TYPE_INSTITUTION,
    UNIT_TYPE_EMERGENCY
  },
  PHOTO_SIZE: 2,
  BANNER_SIZE: 3,
  data() {
    return {
      availableUnitChildren: [],
      form: {
        unitType: UNIT_TYPE_INSTITUTION,
        title: '',
        banner: '',
        photo: '',
        description: '',
        unitChildren: [],
        hasParent: false,
      },
      formRules: {
        title: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        banner: [
          {required: false, message: this.$t('validators.field_required'),},
        ],
        // TODO: file size validators
        photo: [
          {required: false, message: this.$t('validators.field_required'),},
        ],
        description: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
    isEmergency() {
      return this.form.unitType === UNIT_TYPE_EMERGENCY
    },
    unitChildrenNoDataText() {


      return this.form.hasParent ? this.$t('page_unit_edit.unit_children_has_parent') : null
    }
  },
  created() {
    this.fetchUnit()
  },
  methods: {
    goToUnitPage() {
      const {unitID} = this.$route.params
      this.$router.push({name: UNIT_ABOUT_ROUTE_NAME, params: {unitID}})
    },
    fetchUnit() {
      const INVALID_UNIT_RESPONSE_TEMPLATE = 'Invalid UUID'
      const {unitID} = this.$route.params
      Promise.all([fetchUnitEditData(unitID), fetchAvailableUnitChildrenByUnitId(unitID)])
          .then(([unitInfo, availableChildren]) => {

            this.availableUnitChildren = availableChildren.data
            const selectedUnitChildren = availableChildren.data.filter(({isSelected}) => {
              return isSelected
            })
            const selectedUnitChildrenIds = selectedUnitChildren.map(({unitId}) => unitId)
            this.form = {
              ...unitInfo,
              unitChildren: [...selectedUnitChildrenIds],
              banner: '',
              photo: ''
            }
          })
          .catch((e) => {
            if (e.data?.message.includes(INVALID_UNIT_RESPONSE_TEMPLATE)) {
              this.$router.push({name: 'home'})
            }
          })
    },
    handleUpdateUnit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return

        const {unitID} = this.$route.params
        const {description, title, photo, banner, unitChildren} = this.form
        updateUnit({
          description,
          title,
          photo,
          banner,
          unitID,
          unitChildren
        }).then(() => {
              this.goToUnitPage()
              this.$message({
                showClose: true,
                message: `${this.$t('page_unit_edit.messages.edit_success')}`,
                type: 'success'
              })
            })
            .catch((error) => {
              this.$message({
                showClose: true,
                message: error?.data,
                type: 'error'
              })
            })
      })
    }
  }
}
</script>
